@tailwind base;
@tailwind components;
@tailwind utilities;


body {
    font-family: 'Bebas Neue', sans-serif;
    font-family: 'Cardo', serif;
    font-family: 'Outfit', sans-serif;
    font-family: 'Poppins', sans-serif; 
    
 }
 

@keyframes pulse {
    50% {
        opacity: .5;
    }
}
.hover\:animate-pulse2:hover {
    animation: pulse 0.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

